import React from 'react';
import { useDispatch } from 'react-redux';
import { checkOut, deleteGuest } from 'redux/slices';
import { ConfirmationModal } from './ConfirmationModal';

export function RemovePlayer({ playerId, isPlaying = false, isGuestList }) {
  const dispatch = useDispatch();

  const handleRemovePlayer = () => {
    dispatch(checkOut(playerId));
    if (isPlaying !== false) isPlaying();
    if (isGuestList) {
      dispatch(deleteGuest(playerId));
    }
  };

  return (
    <ConfirmationModal
      title="Fazer checkout de jogador"
      description="Tem certeza que deseja fazer checkout deste jogador?"
      handleConfirm={handleRemovePlayer}
      handleCancel={() => { }}
      triggerLabel={isGuestList ? 'Remover' : 'Checkout'}
    />
  );
}
