import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState([])('guestList');

export const guestListSlice = createSlice({
  name: 'guestList',
  initialState,
  reducers: {
    createNewGuest: (state, action) => {
      const { playerId, playerName } = action.payload;
      state.push({
        playerId,
        playerName,
      });
    },
    deleteGuest: (state, action) => {
      const playerId = action.payload;
      return state.filter((p) => p.playerId !== playerId);
    },
  },
});

export const {
  createNewGuest,
  deleteGuest,
} = guestListSlice.actions;
