import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TopBar, YoutubePlayer } from 'components';
import { Box, TextField, Button } from '@mui/material';
import { searchYoutube } from 'services/youtube';
import { setCurrentSong } from 'redux/slices';
import { Link } from 'react-router-dom';

export function Youtube() {
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const data = await searchYoutube(search);
    setIsLoading(false);
    setVideos(data);
    setSearch('');
  };

  return (
    <Box>
      <TopBar />
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ maxWidth: '800px', width: '100%' }}>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Buscar no Youtube"
              variant="outlined"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              sx={{ mt: 2, mb: 2 }}
              onSubmit={handleSubmit}
              fullWidth
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ width: '100%' }}
              disabled={isLoading}
            >
              Procurar
            </Button>
          </form>
          {isLoading && 'Carregando...'}
          {!isLoading && videos.map((video) => (
            <Box key={video.videoId} onClick={() => dispatch(setCurrentSong(video.videoId))}>
              <img src={`https://i.ytimg.com/vi/${video.videoId}/hqdefault.jpg`} alt={video.title} />
              <p>{video.title}</p>
            </Box>
          ))}
        </Box>
      </Box>
      <YoutubePlayer />
      <Link to="/update-store">Update Store</Link>
      <br />
      <Link to="/players-ranking">Player Ranking</Link>
      <br />
      <Link to="/players-admin">Player Admin</Link>
    </Box>
  );
}
