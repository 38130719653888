import { TopBar, AddPlayer, GuestPlayers } from 'components';
import { Box } from '@mui/material';

export function GuestList() {
  return (
    <Box>
      <TopBar />
      <Box mt={4} />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: { xs: 2, md: 6 },
        }}
      >
        <AddPlayer isGuestList />
      </Box>
      <GuestPlayers />
    </Box>
  );
}
