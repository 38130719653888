import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import WaitingPlayerCard from './WaitingPlayerCard';

export function GuestPlayers() {
  const players = useSelector((state) => state.guestList);
  const [currentGuests, setCurrentGuests] = useState(players);

  const totalPlayers = currentGuests.length;

  useEffect(() => {
    setCurrentGuests(players);
  }, [players]);

  console.log(currentGuests);

  return (
    <Box
      sx={{
        mt: 4,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5" textAlign="center">
        {`${totalPlayers} jogadores na lista:`}
      </Typography>
      <Box
        sx={{
          mt: 2,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {currentGuests?.length > 0
          ? currentGuests
            .map((player) => (
              <WaitingPlayerCard key={`${player.playerId}-${player.playerName}`} player={player} isGuestList />
            ))
          : 'Nenhum jogador na lista'}
      </Box>
    </Box>
  );
}
