/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
import { Button, Box } from '@mui/material';
import { setPlayerTeam, addPlayerToTeam, resetAllTeamsStats } from 'redux/slices';
import { useSelector, useDispatch } from 'react-redux';

export function BalanceTeamsBtn() {
  const players = useSelector((state) => state.players);
  const dispatch = useDispatch();

  const balanceTeams = () => {
    const currentTeamA = players.filter((player) => player.teamId === 1);
    const currentTeamB = players.filter((player) => player.teamId === 2);

    const bothTeams = [...currentTeamA, ...currentTeamB];

    const teamA = [];
    const teamB = [];

    const sortedPlayers = [...bothTeams].sort((a, b) => (b.wins - b.losses) - (a.wins - a.losses));

    // Initial distribution: Alternate players between teams
    sortedPlayers.forEach((player, index) => {
      if (index % 2 === 0) {
        teamA.push(player);
      } else {
        teamB.push(player);
      }
    });

    const teamAValue = teamA.reduce((acc, curr) => acc + (curr.wins - curr.losses), 0);
    const teamBValue = teamB.reduce((acc, curr) => acc + (curr.wins - curr.losses), 0);

    const isDifferenceTooBig = teamAValue > teamBValue
      ? teamAValue - teamBValue > 10
      : teamBValue - teamAValue > 10;

    if (isDifferenceTooBig) {
      let bestPlayer = teamAValue > teamBValue
        ? teamA.sort((a, b) => (b.wins - b.losses) - (a.wins - a.losses))[0]
        : teamB.sort((a, b) => (b.wins - b.losses) - (a.wins - a.losses))[0];

      const worstPlayer = teamAValue > teamBValue
        ? teamB.sort((a, b) => (b.wins - b.losses) - (a.wins - a.losses))[0]
        : teamA.sort((a, b) => (b.wins - b.losses) - (a.wins - a.losses))[0];

      // Ensure we don't move a setter if it results in one team having none
      if (bestPlayer.playerType === 'Setter') {
        const receivingTeam = teamAValue > teamBValue ? teamB : teamA;
        if (receivingTeam.some((p) => p.playerType === 'Setter')) {
          bestPlayer = (teamAValue > teamBValue ? teamA : teamB).find((p) => p.playerType !== 'Setter');
        }
      }

      // Swap players between teams
      if (teamAValue > teamBValue) {
        teamA.splice(teamA.indexOf(bestPlayer), 1);
        teamB.push(bestPlayer);
        teamB.splice(teamB.indexOf(worstPlayer), 1);
        teamA.push(worstPlayer);
      } else {
        teamB.splice(teamB.indexOf(bestPlayer), 1);
        teamA.push(bestPlayer);
        teamA.splice(teamA.indexOf(worstPlayer), 1);
        teamB.push(worstPlayer);
      }
    }

    // Final check to ensure each team has at least one setter
    const teamASetters = teamA.filter((p) => p.playerType === 'Setter');
    const teamBSetters = teamB.filter((p) => p.playerType === 'Setter');

    if (teamASetters.length > 1 && teamBSetters.length === 0) {
      const setterToMove = teamASetters.pop();
      teamA.splice(teamA.indexOf(setterToMove), 1);
      teamB.push(setterToMove);

      // Move the non-setter with the closest wins - losses from teamB to teamA
      const nonSettersTeamB = teamB.filter((p) => p.playerType !== 'Setter');
      if (nonSettersTeamB.length > 0) {
        const closestNonSetter = nonSettersTeamB.sort((a, b) => Math.abs(a.wins - a.losses) - Math.abs(b.wins - b.losses))[0];
        teamB.splice(teamB.indexOf(closestNonSetter), 1);
        teamA.push(closestNonSetter);
      }
    } else if (teamBSetters.length > 1 && teamASetters.length === 0) {
      const setterToMove = teamBSetters.pop();
      teamB.splice(teamB.indexOf(setterToMove), 1);
      teamA.push(setterToMove);

      // Move the non-setter with the closest wins - losses from teamA to teamB
      const nonSettersTeamA = teamA.filter((p) => p.playerType !== 'Setter');
      if (nonSettersTeamA.length > 0) {
        const closestNonSetter = nonSettersTeamA.sort((a, b) => Math.abs(a.wins - a.losses) - Math.abs(b.wins - b.losses))[0];
        teamA.splice(teamA.indexOf(closestNonSetter), 1);
        teamB.push(closestNonSetter);
      }
    }

    // Dispatch team assignments
    teamA.forEach((player) => {
      dispatch(setPlayerTeam({ playerId: player.playerId, teamId: 1 }));
      dispatch(addPlayerToTeam({ playerId: player.playerId, teamId: 1 }));
    });

    teamB.forEach((player) => {
      dispatch(setPlayerTeam({ playerId: player.playerId, teamId: 2 }));
      dispatch(addPlayerToTeam({ playerId: player.playerId, teamId: 2 }));
    });
    dispatch(resetAllTeamsStats());
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100px',
      }}
    >
      <Button variant="contained" onClick={balanceTeams} size="small">Balancear times</Button>
    </Box>
  );
}
